'use client';

import { usePathname } from 'next/navigation';
import React, { ReactNode } from 'react';

import { useUser } from '@/shared/api/user/useUser';

export const WithHeaderLayout = ({
  headerSlot,
  advSlot,
}: {
  headerSlot: ReactNode;
  advSlot: ReactNode;
}) => {
  const pathname = usePathname();
  const { user } = useUser();
  if (
    pathname.includes('cart') ||
    pathname.includes('inventory') ||
    pathname.includes('drop') ||
    RegExp('^\\/[a-z]{2}\\/shop$').test(pathname) ||
    RegExp('^\\/[a-z]{2}\\/faq(\\/[a-z-]*)$').test(pathname) ||
    RegExp('^\\/[a-z]{2}\\/profile/history(\\/[a-z-]*)$').test(pathname) ||
    RegExp('^\\/[a-z]{2}\\/categories(\\/[a-z-]*)$').test(pathname) ||
    RegExp('^\\/[a-z]{2}\\/categories(?:\\/mm2)?-[a-z-]*$').test(pathname)
  ) {
    return <header id={'header-portal'} />;
  }

  return (
    <>
      {/*{user && advSlot}*/}
      {headerSlot}
    </>
  );
};
